<template>
  <div class="radio-buttons">
    <div class="radio-button" v-for="option in options" :key="option.id">
      <input
        v-model="val"
        v-bind="$attrs"
        v-on="{ ...$listeners, change: () => {} }"
        type="radio"
        :name="id"
        :value="option.id"
        :id="`${option.id}_${id}`"
        :checked="checked(option)"
        class="radio-button__input"
      />
      <label :for="`${option.id}_${id}`" class="radio-button__label">
        <span>{{ option.name }}</span>
      </label>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomRadio',
  props: {
    id: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    val: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('change', value)
      },
    },
  },
  methods: {
    checked(option) {
      return this.value == option.id || option.sort == this.options.length - 1
    },
  },
}
</script>
<style lang="scss" scoped>
.radio-buttons {
  display: flex;
  margin-top: 8px;
  margin-bottom: 12px;
}

.radio-button {
  display: flex;
  align-items: center;
  margin-right: 32px;

  &__label {
    position: relative;
    // padding-left: 30px;
    cursor: pointer;
    color: $black-200;
    font-size: 16px;
    line-height: 24px;

    span {
      position: relative;
      left: 30px;
      padding-right: 32px;
    }

    &:before {
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      margin: auto;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #dadfe4;
    }
  }

  &__input {
    position: absolute;
    left: -9999px;

    &:checked ~ label {
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        left: 7px;
        margin: auto;
        background: $white;
      }

      &::before {
        background: $blue-120;
        border: 1px solid $blue-120;
      }

      &:hover::before {
        background-color: #006bc9;
        border: 1px solid #006bc9;
      }
    }

    &:not(:checked):hover ~ label {
      &::before {
        border: 1px solid #99a5b3;
      }
    }
  }
}
</style>
