<template>
  <div class="wrap">
    <div v-if="$slots.label" class="label-wrapper">
      <label :for="id" class="label">
        <slot name="label"></slot>
      </label>
    </div>
    <div class="field">
      <el-select
        v-model="value"
        v-bind="$attrs"
        :id="id"
        plain
        size="big"
        :multiple="$attrs.multiple"
        filterable
        :disabled="disabled"
        no-data-text="Справочник пуст"
        no-match-text="Не найдено"
        class="dropdown-el"
        :class="[
          classes,
          invalid && 'error',
          $slots.left && 'left-padding',
          $attrs.multiple && 'multi-dropdown',
        ]"
        @change="onChange"
      >
        <el-option v-if="$slots.default" :value="undefined" selected disabled>
        </el-option>
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
          class="dropdown-el__option"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui'
export default {
  name: 'SelectInput',
  inheritAttrs: false,
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: 'text-input',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(value) {
      this.$emit('input', value)
    },
  },
  components: { 'el-select': Select, 'el-option': Option },
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;

  & ::v-deep .el-select__caret.is-reverse {
    transform: rotateZ(180deg);
  }

  & ::v-deep .el-select__caret {
    transform: rotateZ(0deg);
  }

  & ::v-deep .el-input__inner {
    border-radius: 4px;
    font-size: 16px;
    color: $black-190;
    font-family: Roboto, 'Roboto', sans-serif;
  }

  & ::v-deep .el-input__suffix {
    position: absolute;
    top: 0;
    right: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    pointer-events: none;
    height: 100%;
    width: 24px;
    display: inline-block;
    text-align: center;

    &-inner {
      pointer-events: none;
    }
  }

  & ::v-deep .el-input__prefix {
    left: 0 !important;

    .el-input__icon:before {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -12px;
    }
  }

  & ::v-deep .el-icon-arrow-up::before {
    content: url('../../assets/img/arrow-down.svg');
    position: relative;
    top: 8px;
  }

  & ::v-deep .el-input__icon {
    width: 24px;
    height: 100%;
    display: inline-block;
    line-height: 20px;
    transition: all 0.3s;

    &:after {
      content: '';
      height: 100%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }

  & ::v-deep &__input {
    border: none;
    background-color: inherit;
  }

  &.big:not(.multi-dropdown) ::v-deep .el-input,
  &.big:not(.multi-dropdown) ::v-deep .el-input__inner {
    height: 40px;
    line-height: 40px;
  }

  &:not(.multi-dropdown):hover ::v-deep .el-input__inner {
    background: $black-10 !important;
  }
}
</style>
<style lang="scss">
.el-select-dropdown.el-popper {
  font-family: Roboto, 'Roboto', sans-serif;
  border: 1px solid $black-20;
  border-radius: 4px;
  background-color: $white;
  box-shadow: 0px 4px 15px rgba(33, 39, 44, 0.1);
  box-sizing: border-box;
  margin: 5px 0;

  .el-select-dropdown__item {
    font-family: Roboto, 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 40px;
    height: 40px;
    color: $black-200;
    padding: 0 16px !important;

    &.selected {
      color: $blue-120;
    }

    &:hover {
      background-color: $black-10;
    }
  }

  .el-select-dropdown__wrap {
    overflow: auto;
    height: 100%;
    max-height: 300px;
    margin: 0 !important;
  }
}

.dropdown-el__option {
  font-family: Roboto, 'Roboto', sans-serif;

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      cursor: pointer;
    }
  }

  &.is-disabled {
    color: $black-50 !important;

    &:hover {
      background-color: unset;
    }

    & ::v-deep span {
      pointer-events: none;
    }
  }
}

.dropdown-el {
  width: 100%;

  .el-select__tags {
    padding-left: 4px;

    & > span {
      .el-tag {
        background: $black-10;
        border: 1px solid #dadfe4;
        box-sizing: border-box;
        border-radius: 4px;

        &__close {
          background-color: transparent;
          color: $black-80;

          &:hover {
            background-color: transparent;
            color: $black-90;
          }
        }

        .el-select__tags-text {
          color: $black-80;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }

        &:hover {
          background: $black-20;
          border: 1px solid transparent;

          &__close {
            background-color: transparent;
            color: $black-80;
          }

          .el-select__tags-text {
            color: $black-80;
          }
        }

        &:disabled {
          background: $black-20;
          border: 1px solid #dadfe4;

          &__close {
            background-color: transparent;
            color: $black-50;
          }

          .el-select__tags-text {
            color: $black-50;
          }
        }
      }
    }
  }
}
</style>
