<template>
  <div class="form-wrapper" :class="[lastStep && 'final']">
    <img
      v-if="lastStep"
      src="/proxy.php?className=login&action=get_logo&file_request=true"
      alt="logo"
      class="logo"
    />
    <h1 v-if="lastStep && page && page.page_name" class="title">
      {{ page.page_name }}
    </h1>
    <p
      v-if="page && page.description"
      class="subtitle"
      v-html="formatText(page.description)"
    ></p>
    <form
      v-if="!lastStep"
      novalidate
      @submit.prevent="toNextPage"
      :class="{ submitted, documents: onlyFiles }"
    >
      <div v-for="question in questions" :key="question.id" class="form-field">
        <template
          v-if="
            (isGroup(question) || isMultigroup(question) || isBase(question)) &&
            question.subquestions &&
            visible(question)
          "
        >
          <div
            v-if="
              question.condition_display ? !question.hide : !question.options
            "
            class="block-title bold"
            v-html="question.name"
          ></div>
          <group-field
            :question="question"
            :errors="errors"
            :requirement-data="conditionRequirementData.requiredQuestions"
            @inputHandler="inputHandler"
            @selectHandler="selectHandler"
            @checkboxHandler="checkboxHandler"
            @radioHandler="radioHandler"
            @update-files="updateQuestion"
            @upload-files="uploadFiles"
            @updateEmptyError="updateEmptyError"
            @updateSizeError="updateSizeError"
            @addGroup="addGroup"
            @removeGroup="removeGroup"
          ></group-field>
        </template>
        <field-item
          v-else
          :question="question"
          :errors="errors"
          :requirement-data="conditionRequirementData.requiredQuestions"
          @inputHandler="inputHandler"
          @selectHandler="selectHandler"
          @checkboxHandler="checkboxHandler"
          @radioHandler="radioHandler"
          @update-files="updateQuestion"
          @upload-files="uploadFiles"
          @updateEmptyError="updateEmptyError"
          @updateSizeError="updateSizeError"
          @addGroup="addGroup"
          @removeGroup="removeGroup"
        ></field-item>
      </div>
      <div class="page-footer">
        <div v-if="activeStepIndex" class="page-footer__button">
          <custom-button type="button" class="transparent" @click="goBack"
            >Назад</custom-button
          >
        </div>
        <div class="page-footer__button">
          <custom-button
            :disabled="fileSizeError || hasEmptyError"
            class="darkBlue"
            @click="submit = true"
          >
            {{ !beforeLastStep ? 'Далее' : 'Завершить' }}
          </custom-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CustomButton from '@/components/elements/Button'
import GroupField from '@/components/elements/GroupField'
import FieldItem from '@/components/elements/FieldItem'
import { saveQuestions } from '@/controllers/page'
import { mixin } from '@/utils/mixins'

export default {
  mixins: [mixin],
  components: {
    CustomButton,
    GroupField,
    FieldItem,
  },
  data() {
    return {
      hasError: false,
      submitted: false,
      errors: {},
      emptyError: {},
      fileSizeError: false,
      conditionRequirementData: {
        requiredQuestions: [],
        answers: {},
      },
      reactiveCondition: {
        form: {}, // опции и текущие значения таких полей
        conditionFields: {}, // созависимые поля
      },
    }
  },
  created() {
    this.setDefaultValue()
  },
  mounted() {
    this.$store.commit('loading', false)
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  },
  computed: {
    questions() {
      return this.$store.state.page.questions
    },
    page() {
      return (
        this.steps[this.activeStepIndex] &&
        this.$store.state.page.questionaire[this.steps[this.activeStepIndex].id]
      )
    },
    activeStepIndex() {
      return this.$store.state.page.activeStepIndex
    },
    steps() {
      return this.$store.state.page.steps.map((s, index) => ({
        ...s,
        index,
      }))
    },
    beforeLastStep() {
      return this.steps.length - 2 == this.activeStepIndex
    },
    lastStep() {
      return this.steps.length - 1 == this.activeStepIndex
    },
    onlyFiles() {
      return this.$store.state.page.questions.every(
        (q) => q.answer_type == 'file_answer'
      )
    },
    activeMenuItem() {
      return this.$store.state.page.activeMenu
    },
    hasEmptyError() {
      // наличие значения и наличие в форме
      return Object.keys(this.emptyError).some(
        (key, i) => !!document.getElementById(key) && !!this.emptyError[i]
      )
    },
  },
  methods: {
    visible(question) {
      return !question.hide && !question.hideAll
    },
    isGroup(question) {
      return question.question_type == 'group'
    },
    isBase(question) {
      return question.question_type == 'base'
    },
    isMultigroup(question) {
      return question.question_type == 'multigroup'
    },
    updateQuestion(question, value) {
      this.$store.dispatch('page/updateQuestion', { question, value })
    },
    requiredQuestionsRecursiveUpdate(questions, requireData) {
      questions.forEach((q) => {
        if (
          q.options &&
          q.options.some(
            ({ id }) =>
              requireData.answers[id] &&
              q.value === id &&
              !+requireData.answers[id].required
          )
        ) {
          this.addRequiredQuestions(q, q.value, requireData)
        } else if (q.subquestions) {
          this.requiredQuestionsRecursiveUpdate(q.subquestions, requireData)
        } else if (Array.isArray(q)) {
          q.forEach((s) => {
            if (s.subquestions) {
              s.subquestions.forEach((i) => {
                if (
                  i.options &&
                  i.options.some(
                    ({ id }) =>
                      requireData.answers[id] &&
                      i.value === id &&
                      !+requireData.answers[id].required
                  )
                ) {
                  this.addRequiredQuestions(i, i.value, requireData)
                }
              })
            }
          })
        }
      })
    },
    addRequiredQuestions(question, value, { requiredQuestions, answers }) {
      if (!question.watch_value) {
        this.$set(question, 'watch_value', answers[value].question_id)
      }

      if (!requiredQuestions.includes(answers[value].question_id)) {
        requiredQuestions.push(answers[value].question_id)
      }
    },
    selectHandler(question, value) {
      const {
        answers = {},
        requiredQuestions = [],
      } = this.conditionRequirementData
      if (answers[value] && !+answers[value].required) {
        this.addRequiredQuestions(question, value, {
          requiredQuestions,
          answers,
        })
      }

      if (
        question.watch_value &&
        !answers[value] &&
        requiredQuestions.includes(question.watch_value)
      ) {
        const index = requiredQuestions.indexOf(question.watch_value)
        requiredQuestions.splice(index, 1)
      }

      this.updateQuestion(question, value)
      this.setDefaultValue()
    },
    inputHandler({ question, $event }) {
      this.updateQuestion(question, $event)
      this.submitted = false
    },
    checkboxHandler(subquestion, $event) {
      const questions = this.$store.state.page.questions

      questions.forEach((q) => {
        if (q.id === subquestion.id) {
          this.$set(
            q,
            'value',
            q.options
              ? $event.target.checked
                ? q.options[0].id
                : ''
              : $event.target.checked
          )
        }

        if (+subquestion.hide_all) {
          this.setHideAll(
            q,
            !!+subquestion.hide_all &&
              $event.target.checked &&
              subquestion.id !== q.id
          )
        }

        if (
          !subquestion.hide_all &&
          subquestion.options &&
          q.condition_display &&
          subquestion.options.some((o) =>
            Array.isArray(q.condition_display)
              ? q.condition_display.includes(o.id)
              : o.id == q.condition_display
          ) &&
          q.id !== subquestion.id
        ) {
          this.$set(
            q,
            'hide',
            $event.target.checked &&
              (Array.isArray(q.condition_display)
                ? q.condition_display.includes(subquestion.options[0].id)
                : q.condition_display === subquestion.options[0].id)
          )

          // проверка других связей поля, связанного с чекбоксом
          if (
            Array.isArray(q.condition_display) &&
            q.condition_display.length > 1 &&
            !$event.target.checked
          ) {
            this.$set(
              q,
              'hide',
              questions.some(
                (qq) =>
                  qq.options &&
                  qq.options.some((o) => q.condition_display.includes(o.id)) &&
                  !qq.options.some((o) => subquestion.options[0].id === o.id) &&
                  !q.condition_display.includes(qq.value)
              )
            )
          }
        }

        if (q.subquestions) {
          q.subquestions.forEach((s) => {
            if (Array.isArray(s)) {
              s.forEach((sub) => {
                if (sub.id === subquestion.id) {
                  this.$set(
                    sub,
                    'value',
                    sub.options
                      ? $event.target.checked
                        ? sub.options[0].id
                        : ''
                      : $event.target.checked
                  )
                }

                if (!subquestion.options) return
                if (!sub.condition_display) return
                if (
                  !subquestion.options.some(
                    (o) =>
                      o.id ==
                      (Array.isArray(sub.condition_display)
                        ? sub.condition_display[0]
                        : sub.condition_display)
                  )
                )
                  return

                if (
                  sub.id !== subquestion.id &&
                  subquestion.sub_group_num == sub.sub_group_num
                ) {
                  this.$set(
                    sub,
                    'hide',
                    $event.target.checked &&
                      (Array.isArray(sub.condition_display)
                        ? sub.condition_display[0]
                        : subquestion.options && sub.condition_display) ===
                        subquestion.options[0].id
                  )
                }
              })
            }
          })
        }
      })

      this.setDefaultValue()
      this.$store.commit('page/setQuestions', questions)
    },
    radioHandler({ val, question }) {
      this.updateQuestion(question, val)

      // update visibility
      const questions = this.$store.state.page.questions

      questions.forEach((q) => {
        if (Array.isArray(q.subquestions) && q.subquestions.length) {
          q.subquestions.forEach((s) => {
            if (Array.isArray(s)) {
              s.forEach((sub) => {
                this.updateVisibleFields(val, question, sub, s)

                if (sub.subquestions && Array.isArray(sub.subquestions)) {
                  sub.subquestions.forEach((ss) =>
                    this.updateVisibleFields(
                      val,
                      question,
                      ss,
                      sub.subquestions
                    )
                  )
                }
              })
            }
          })
        }

        this.updateVisibleFields(val, question, q, questions)
      })

      this.setDefaultValue()
      this.$store.commit('page/setQuestions', questions)
    },
    checkByCondition(parentList, item) {
      const conditionParam = Array.isArray(item.condition_display)
        ? item.condition_display[0]
        : item.condition_display
      const parentQuestion =
        parentList.find(
          (qq) => qq.options && qq.options.some((o) => o.id == conditionParam)
        ) || null

      if (!parentQuestion) {
        const hide = Object.entries(this.reactiveCondition.form).some(
          (opt) => opt[0] == conditionParam && opt[1] && opt[1] != opt[0]
        )
        this.$set(item, 'hide', hide)
      }

      // отслеживаем скрытие созависимых полей через цепочку "поле - поле - поле" на разных уровнях
      if (
        item.subquestions &&
        Array.isArray(item.subquestions) &&
        conditionParam
      ) {
        item.subquestions.forEach((sub) => {
          if (sub.options) {
            sub.options.forEach((o) => {
              const fieldId = this.reactiveCondition.conditionFields[o.id]
              const field = this.$store.state.page.questions.find(
                (q) => q.id == fieldId
              )
              if (!field) return
              if (fieldId) {
                const parentHide = Object.entries(
                  this.reactiveCondition.form
                ).some(
                  (opt) =>
                    opt[0] == conditionParam && opt[1] && opt[1] != opt[0]
                )
                const hide = Object.entries(this.reactiveCondition.form).some(
                  (opt) => opt[0] == o.id && opt[1] && opt[1] != opt[0]
                )
                this.$set(field, 'hide', hide || parentHide)
              }
            })
          }
        })
      }

      this.updateVisibleFields(
        parentQuestion?.value,
        parentQuestion,
        item,
        parentList
      )
    },
    setRequirementData(question) {
      this.$set(
        this.conditionRequirementData.answers,
        question.condition_requirement,
        {
          question_id: question.etalon_id || question.id,
          required: !!+question.required,
        }
      )
    },
    updateVisibleFields(value, parent, question, questions) {
      if (!parent) return
      const conditionParam = question.condition_display
      const isRadio = parent.options?.length > 1

      if (!conditionParam) return
      if (
        !parent.options.some((o) =>
          Array.isArray(conditionParam)
            ? conditionParam.includes(o.id)
            : o.id == conditionParam
        )
      )
        return

      if (Array.isArray(conditionParam)) {
        const hide = !conditionParam.includes(value) && !!value
        this.$set(question, 'hide', hide)
        if (hide) {
          this.$set(question, 'delete', true)
        } else {
          this.$set(question, 'delete', false)
        }

        // проверка других связей поля, связанного с чекбоксом
        if (
          !hide &&
          conditionParam.filter((qq) => !parent.options.some((o) => o.id == qq))
            .length > 0
        ) {
          this.$set(
            question,
            'hide',
            questions.some(
              (qq) =>
                qq.options &&
                qq.options.some((o) => conditionParam.includes(o.id)) &&
                !qq.options.some((o) => value === o.id) &&
                conditionParam.includes(qq.value)
            )
          )
          if (
            questions.some(
              (qq) =>
                qq.options &&
                qq.options.some((o) => conditionParam.includes(o.id)) &&
                !qq.options.some((o) => value === o.id) &&
                conditionParam.includes(qq.value)
            )
          ) {
            this.$set(question, 'delete', true)
          } else {
            this.$set(question, 'delete', false)
          }
        }
      } else {
        this.$set(
          question,
          'hide',
          !!value &&
            (isRadio ? conditionParam != value : conditionParam == value)
        )
        if (
          !!value &&
          (isRadio ? conditionParam != value : conditionParam == value)
        ) {
          this.$set(question, 'delete', true)
        } else {
          this.$set(question, 'delete', false)
        }
      }
    },
    setDefaultValue() {
      const questions = this.$store.state.page.questions || []

      questions.forEach((q) => {
        if (q.subquestions && Array.isArray(q.subquestions)) {
          q.subquestions.forEach((sub, i) => {
            if (Array.isArray(sub)) {
              if (i == 0 && !q.etalon_ids) {
                this.$set(
                  q,
                  'etalon_ids',
                  sub.map(({ id }) => id)
                )
                this.$set(q, 'etalon_sub_ids', {})
              }
              sub.forEach((s, ii) => {
                // для вопросов внутри мультигруп-поля задаем уникальные айдишники и эталонные
                if (i == 0 && s.subquestions && Array.isArray(s.subquestions)) {
                  s.subquestions.forEach((ss, iii) => {
                    if (!q.etalon_sub_ids[`${ii}_${iii}`])
                      this.$set(q.etalon_sub_ids, `${ii}_${iii}`, ss.id)
                  })
                }

                this.$set(s, 'id', `${q.id}_${i}_${ii}`)
                if (!s.etalon_id) this.$set(s, 'etalon_id', q.etalon_ids[ii])
                if (s.subquestions && Array.isArray(s.subquestions)) {
                  s.subquestions.forEach((ss, iii) => {
                    this.$set(ss, 'id', `${q.id}_${i}_${ii}_${iii}`)
                    if (!ss.etalon_id)
                      this.$set(
                        ss,
                        'etalon_id',
                        q.etalon_sub_ids[`${ii}_${iii}`]
                      )

                    if (ss.options) {
                      ss.options.forEach((opt) =>
                        this.$set(this.reactiveCondition.form, opt.id, ss.value)
                      )
                    }

                    if (ss.condition_display) {
                      this.$set(
                        this.reactiveCondition.conditionFields,
                        ss.condition_display,
                        ss.id
                      )
                    }
                  })
                }

                if (
                  !s.value &&
                  s.answer_type == 'option_answer' &&
                  s.options &&
                  s.options.length > 1
                ) {
                  this.$set(s, 'value', s.options[s.options.length - 1].id)
                }

                if (s.answer_type == 'dropdown_answer' && !s.value) {
                  this.$set(s, 'value', s.options[0].id)
                }

                if (s.options) {
                  s.options.forEach((opt) =>
                    this.$set(this.reactiveCondition.form, opt.id, s.value)
                  )
                }

                if (s.condition_display) {
                  this.$set(
                    this.reactiveCondition.conditionFields,
                    s.condition_display,
                    s.id
                  )
                  this.checkByCondition(sub, s)
                }

                if (s.condition_requirement) {
                  this.setRequirementData(s)
                }

                if (
                  s.hide_all &&
                  !!+s.hide_all &&
                  (s.value == '1' || s.value == 'Да') &&
                  Array.isArray(questions)
                ) {
                  questions.forEach(
                    (qq) => qq.id !== s.id && this.setHideAll(qq, true)
                  )
                }
              })
            }

            if (
              !sub.value &&
              sub.answer_type == 'option_answer' &&
              sub.options &&
              sub.options.length > 1
            ) {
              this.$set(sub, 'value', sub.options[sub.options.length - 1].id)
            }
            if (sub.options && Array.isArray(sub.options)) {
              sub.options.forEach((opt) =>
                this.$set(this.reactiveCondition.form, opt.id, sub.value)
              )
            }

            if (sub.condition_display) {
              this.$set(
                this.reactiveCondition.conditionFields,
                sub.condition_display,
                sub.id
              )
              this.checkByCondition(q.subquestions, sub)
            }

            if (sub.condition_requirement) {
              this.setRequirementData(sub)
            }

            if (
              sub.hide_all &&
              !!+sub.hide_all &&
              (sub.value == '1' || sub.value == 'Да') &&
              Array.isArray(questions)
            ) {
              questions.forEach(
                (qq) => qq.id !== sub.id && this.setHideAll(qq, true)
              )
            }
          })
        }
        if (q.options && Array.isArray(q.options)) {
          q.options.forEach((opt) =>
            this.$set(this.reactiveCondition.form, opt.id, q.value)
          )
        }

        if (q.condition_display) {
          this.$set(
            this.reactiveCondition.conditionFields,
            q.condition_display,
            q.id
          )
          this.checkByCondition(questions, q)
        }

        if (q.condition_requirement) {
          this.setRequirementData(q)
        }

        if (
          q.hide_all &&
          !!+q.hide_all &&
          (q.value == '1' || q.value == 'Да')
        ) {
          questions.forEach((qq) => qq.id !== q.id && this.setHideAll(qq, true))
        }

        // если есть сохраненные данные
        if (q.value || !q.options) return

        switch (q.answer_type) {
          case 'dropdown_answer':
            this.$set(q, 'value', q.options[0].id)
            break
          case 'option_answer':
            if (q.options?.length > 1)
              this.$set(q, 'value', q.options[q.options.length - 1].id)

            if (!q.subquestions && !!q.options) {
              questions
                .filter(
                  (qq) => qq.condition_display == q.options[0].id && q.value
                )
                .forEach((qq) => this.$set(qq, 'hide', true))
            }
            break
        }
      })

      if (Object.keys(this.conditionRequirementData.answers).length) {
        this.requiredQuestionsRecursiveUpdate(
          questions,
          this.conditionRequirementData
        )
      }

      this.$store.commit('page/setQuestions', questions)
    },
    addGroup(items, parentQuestion) {
      const sub_group_num = Date.now()

      items = items.map((i, idx) => {
        const subquestions = i.subquestions
          ? {
              subquestions: i.subquestions.map((s, index) => ({
                ...s,
                answer_id: null,
                sub_group_num,
                value: null,
                hide: true,
                additional: true,
                etalon_id: parentQuestion.etalon_sub_ids[`${idx}_${index}`],
                id: Date.now() + '_' + index,
              })),
            }
          : {}

        return {
          ...i,
          answer_id: null,
          sub_group_num,
          value: null,
          hide: false,
          additional: true,
          etalon_id: parentQuestion.etalon_ids[idx],
          id: Date.now() + '_' + idx,
          ...subquestions,
        }
      })

      this.$store.dispatch('page/addItemToGroup', {
        items,
        id: parentQuestion.id,
      })
      this.setDefaultValue()
    },
    removeGroup({ item, index }) {
      this.$store.dispatch('page/removeItemFromGroup', { item, index })
    },
    async goBack() {
      this.$store.commit('loading', true)

      try {
        await saveQuestions({
          questions: this.$store.state.page.questions,
          page_num: this.page.sort,
          page_id: this.page.page_id,
        })
        if (this.activeStepIndex - 1 >= 0) {
          this.$store.commit(
            'page/setActiveStepIndex',
            this.steps[this.activeStepIndex - 1]
          )
          await this.$store.dispatch('page/loadQuestionaire', {
            page: this.activeStepIndex,
          })
        }

        this.$store.commit('loading', false)
        this.$router.push({
          name: 'questionnairePage',
          params: {
            questionnaire: this.activeMenuItem.module_key,
            step: this.steps[this.activeStepIndex - 1]?.id,
          },
        })
      } catch (e) {
        console.error(e)
      }
    },
    async toNextPage() {
      this.$store.commit('loading', true)
      this.errors = {}
      document
        .querySelectorAll('.is-required input')
        .forEach((el) => !el.value && this.$set(this.errors, el.id, true))
      document.querySelectorAll('form input, form textarea').forEach((el) => {
        if (
          el.required &&
          (el.dataset.val ? !+el.dataset.val && !el.value : !el.value)
        ) {
          this.$set(this.errors, el.id, true)
        }

        if (
          !this.errors[el.id] &&
          el.value &&
          el.minLength > -1 &&
          el.maxLength > -1
        ) {
          this.$set(this.errors, el.id, el.value.length < el.minLength)
        }
      })

      this.hasError = Object.values(this.errors).some((err) => !!err)

      if (!this.hasError && !this.fileSizeError && !this.hasEmptyError) {
        try {
          await saveQuestions({
            questions: this.$store.state.page.questions,
            page_num: this.page.sort,
            page_id: this.page.page_id,
            final_page: this.beforeLastStep,
          })
          this.$store.commit(
            'page/setActiveStepIndex',
            this.steps[this.activeStepIndex + 1]
          )
          await this.$store.dispatch('page/loadQuestionaire', {
            page: this.activeStepIndex,
          })
          this.$store.commit('loading', false)

          this.$router.push({
            name: 'questionnairePage',
            params: {
              questionnaire: this.activeMenuItem.module_key,
              step: !this.beforeLastStep
                ? this.steps[this.activeStepIndex + 1]?.id
                : 'finish',
            },
          })
        } catch (e) {
          this.$store.commit('showNotification', {
            message: 'Произошла ошибка, попробуйте еще раз',
            type: 'error',
          })
        }
      } else {
        this.$store.commit('loading', false)

        this.$nextTick(() => {
          if (!document.querySelector('.error')) return

          const el =
            document.querySelector('.error').closest('.input-block') ||
            document.querySelector('.error').closest('.field') ||
            document.querySelector('.error').closest('.form-field')
          window.scrollTo({
            top: el.offsetTop,
            behavior: 'smooth',
          })
        })
      }
    },
    async uploadFiles(files = [], question) {
      // this.files = files
      const resp = await this.$store.dispatch('page/uploadFiles', {
        question,
        files,
        page_id: this.page.page_id,
      })

      if (!resp?.data && resp?.error_message == "Files didn't attach") {
        document.getElementById(question.id).value = ''
        this.updateEmptyError(true, question)
      }

      // if (success) this.files = []
    },
    updateEmptyError(bool, question) {
      this.$set(this.emptyError, question.id, bool)
    },
    updateSizeError(bool) {
      this.fileSizeError = bool
    },
    setHideAll(question, value) {
      this.$set(question, 'hideAll', value)
      if (question.subquestions) {
        question.subquestions.forEach((elem) => {
          if (Array.isArray(elem)) {
            elem.forEach((q) => {
              this.setHideAll(q, value)
            })
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/questionnaire.scss';

.error {
  color: #fb5a5a;
  width: 100%;
  text-align: center;
}

.page-footer {
  width: 100%;
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;

  &__button {
    width: 120px;

    &_large {
      width: 200px;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__link {
    color: #888a8f;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }
}

.final {
  width: 514px;
  text-align: center;
}
</style>
