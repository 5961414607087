var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"document"},[_c('div',{staticClass:"document__type",class:[_vm.invalid && 'error']},[_c('div',{staticClass:"document__left"},[_c('span',{staticClass:"document__type-name",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.name))}}),(_vm.invalid)?_c('span',{staticClass:"error-msg red fz-14"},[_vm._v(" Нужно загрузить документ ")]):_vm._e()]),_c('div',{staticClass:"document__right"},[_c('file-input',{attrs:{"id":_vm.id,"required":_vm.required,"data-val":!!_vm.value && !!_vm.value.length ? '1' : '0'},on:{"upload":function($event){return _vm.changeHandler($event)}}},[_c('div',{attrs:{"slot":"label"},slot:"label"})])],1)]),_c('div',{staticClass:"input-block"},[(_vm.value)?_c('div',{staticClass:"file-previews"},_vm._l((_vm.value),function(preview){return _c('preview',{key:preview.id,attrs:{"item":{
          name: preview.value.name,
          get: preview.value.get,
          id: preview.id,
          size: _vm.sizeToMb(preview.value.size || preview.value.filesize),
          invalidSize: !_vm.validSize(
            preview.value.size || preview.value.filesize || 0
          ),
        }},on:{"remove":function($event){return _vm.removeFileFromServer(preview)}}})}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }