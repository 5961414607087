export const mixin = {
  methods: {
    formatText(text) {
      if (typeof text === 'string' && text) {
        const replacement = [
          [/&quot;/g, '"'],
          [/&gt;/g, '>'],
          [/&lt;/g, '<'],
          [/&#039;/g, "'"],
          [/%3A/g, ':'],
        ]
        return text
          .replace(...replacement[0])
          .replace(...replacement[1])
          .replace(...replacement[2])
          .replace(...replacement[3])
          .replace(...replacement[4])
      }
      return text
    },
  },
}
