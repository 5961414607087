var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({class:{
    'no-relatives': _vm.isOptionType && !_vm.question.options && _vm.visible,
    'only-field':
      _vm.isOptionType && !_vm.question.options && _vm.visible && !!+_vm.question.value,
    'input-block':
      ((_vm.isLineType && _vm.isBase) || (_vm.isDateType && _vm.isBase)) && _vm.visible,
  }},'div',_vm.$attrs,false),[(_vm.isDropdownType && _vm.visible && _vm.isBase)?_c('select-input',{attrs:{"id":_vm.question.id,"options":_vm.question.options,"required":_vm.required,"invalid":_vm.errors[_vm.question.id] && !_vm.question.value,"value":_vm.question.value},on:{"input":function($event){return _vm.$emit('selectHandler', _vm.question, $event)}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.question.name)},slot:"label"})]):((_vm.isLineType || _vm.isDateType) && _vm.isBase && _vm.visible)?_c('text-input',{attrs:{"id":_vm.question.id,"type":_vm.isDateType ? 'date' : 'text',"required":_vm.required,"mask":_vm.question.input_mask,"invalid":(_vm.errors[_vm.question.id] && !_vm.question.value) || _vm.invalidSymbolsLength,"value":_vm.question.value,"tooltip":_vm.question.placeholder},on:{"input":function($event){return _vm.$emit('inputHandler', { question: _vm.question, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.question.name)},slot:"label"})]):(_vm.isTextType && _vm.isBase && _vm.visible)?_c('text-area',{attrs:{"id":_vm.question.id,"required":_vm.required,"invalid":(_vm.errors[_vm.question.id] && !_vm.question.value) || _vm.invalidSymbolsLength,"limit":_vm.question.answer_length || '',"value":_vm.question.value,"tooltip":_vm.question.placeholder},on:{"input":function($event){!_vm.$emit('inputHandler', { question: _vm.question, $event: $event })}}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.question.name)},slot:"label"})]):(_vm.isTextType && _vm.isComment && _vm.visible && !!_vm.question.value)?_c('text-area',{staticClass:"is-comment",class:_vm.question.value ? 'has-comment-value' : '',attrs:{"value":_vm.question.value,"disabled":_vm.isComment}}):(
      _vm.isOptionType && _vm.visible && (!_vm.question.subquestions || !_vm.question.options)
    )?[(
        !_vm.question.options ||
        (_vm.question.options && _vm.question.options.length == 1)
      )?_c('custom-checkbox',{attrs:{"id":_vm.question.id,"required":_vm.required,"invalid":_vm.errors[_vm.question.id] && !_vm.question.value,"value":_vm.checkboxValue},on:{"change":function($event){return _vm.$emit('checkboxHandler', _vm.question, $event)}}},[_vm._v(" "+_vm._s(_vm.question.name)+" ")]):[_c('div',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.question.name)}}),_c('radio',{attrs:{"id":_vm.question.id,"value":_vm.question.value,"options":_vm.question.options},on:{"change":function (val) { return _vm.$emit('radioHandler', { question: _vm.question, val: val }); }}})]]:(_vm.isFileType && _vm.visible && !_vm.isFileDownloadType)?_c('file-el',{attrs:{"id":_vm.question.id,"name":_vm.question.name,"required":_vm.required,"invalid":_vm.errors[_vm.question.id] && !_vm.question.value,"value":_vm.question.value || []},on:{"upload-files":function (p) { return _vm.$emit('upload-files', p, _vm.question); },"update-files":function (p) { return _vm.$emit('update-files', _vm.question, p); },"set-empty-error":function (p) { return _vm.$emit('updateEmptyError', p, _vm.question); },"set-size-error":function (p) { return _vm.$emit('updateSizeError', p); }}}):(_vm.isFileDownloadType && _vm.visible)?_c('custom-button',{staticClass:"blue",attrs:{"type":"button","disabled":!_vm.question.get}},[_c('Icon',{attrs:{"iconName":"upload","iconColor":"#0075DB"}}),_c('a',{attrs:{"href":("/proxy.php?" + (_vm.question.get))}},[_vm._v(" "+_vm._s(_vm.question.name || '[без имени]')+" ")])],1):(_vm.isScaleType && _vm.visible)?_c('scale',{attrs:{"model":+_vm.question.value,"required":_vm.required},on:{"change":function (p) { return _vm.$emit('radioHandler', { question: _vm.question, val: p }); }}},[_c('div',{attrs:{"slot":"label"},domProps:{"innerHTML":_vm._s(_vm.question.name)},slot:"label"})]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }