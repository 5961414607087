<template>
  <div class="wrap">
    <div v-if="$slots.label" class="label-wrapper">
      <label :for="id" class="label">
        <slot name="label" />
        <custom-button type="button" class="transparent load-btn medium">
          Загрузить
          <input
            v-bind="$attrs"
            type="file"
            :id="id"
            :required="required"
            multiple
            @change="$emit('upload', $event)"
          />
        </custom-button>
      </label>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/elements/Button'
export default {
  inheritAttrs: false,
  props: {
    // hasFiles: {
    //   type: Boolean,
    //   default: false,
    // },
    id: {
      type: [String, Number],
      default: 'file-input',
    },
    required: Boolean,
  },
  components: { CustomButton },
}
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}

.label input[type='file'] {
  height: 100%;

  &::-webkit-file-upload-button {
    cursor: pointer;
    height: 100%;
  }
}

.label-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.load-btn {
  display: flex;
  align-items: center;
}
</style>
