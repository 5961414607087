<template>
  <div class="document">
    <div class="document__type" :class="[invalid && 'error']">
      <div class="document__left">
        <span class="document__type-name" v-html="formatText(name)"></span>
        <span v-if="invalid" class="error-msg red fz-14">
          Нужно загрузить документ
        </span>
        <!-- <custom-button
              type="button"
              class="transparent"
              @click.stop="preload(id)"
            >
              <Icon icon-name="preload" />
            </custom-button> -->
      </div>
      <div class="document__right">
        <file-input
          :id="id"
          :required="required"
          :data-val="!!value && !!value.length ? '1' : '0'"
          @upload="changeHandler($event)"
        >
          <div slot="label"></div>
        </file-input>
      </div>
    </div>
    <div class="input-block">
      <div v-if="value" class="file-previews">
        <preview
          v-for="preview in value"
          :key="preview.id"
          :item="{
            name: preview.value.name,
            get: preview.value.get,
            id: preview.id,
            size: sizeToMb(preview.value.size || preview.value.filesize),
            invalidSize: !validSize(
              preview.value.size || preview.value.filesize || 0
            ),
          }"
          @remove="removeFileFromServer(preview)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import FileInput from '@/components/elements/FileInput'
import Preview from '@/components/elements/FilePreview'
import { mixin } from '@/utils/mixins'
export default {
  mixins: [mixin],
  props: {
    id: String,
    name: String,
    required: Boolean,
    invalid: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    if (this.required) this.$emit('set-empty-error', !this.value.length)
  },
  methods: {
    validSize(value /* files */) {
      const fileSize = parseInt(value / 1024 ** 2)
      return (
        fileSize <= 20
        // &&
        // (files || this.files).reduce(
        //   (acc, file) => acc + +file.size / 1024 / 1024,
        //   0.0
        // ) <= 20
      )
    },
    updateSizeError() {
      this.$emit(
        'set-size-error',
        this.value && this.value.some((f) => f.value?.size > 20 * 1024 ** 2)
      )
    },
    changeHandler(evt) {
      if (!evt.target.files.length) return

      const files = evt.target.files
      this.$emit('set-empty-error', false)
      if (
        Object.values(files).some(
          (file) => parseInt(file.size / 1024 ** 2) > 20
        )
      ) {
        Object.values(files)
          .filter((file) => parseInt(file.size / 1024 ** 2) > 20)
          .forEach((file) =>
            setTimeout(() =>
              this.$store.commit(
                'showNotification',
                {
                  message: `Файл «${file.name}» не должен превышать 20 мБайт`,
                  type: 'error',
                },
                100
              )
            )
          )
        this.$nextTick(() => {
          document.getElementById(this.id).value = ''
          this.$emit('set-empty-error', !this.value?.length)
        })

        return
      }

      // пакетная загрузка
      let packages = this.sortFilesToPackages(files)
      let sizes = []
      packages.forEach((pack) => {
        let packageSize = pack.reduce(function (p, c) {
          return Number(p) + Number(c.size)
        }, '')
        sizes.push(packageSize / 1024 / 1024)
      })

      for (let i = 0; i < packages.length; i++) {
        this.$emit('upload-files', packages[i])
      }
      this.updateSizeError()
    },
    sizeToMb(rawSize) {
      return rawSize && (rawSize / 1024 / 1024).toFixed(2)
    },
    randomNumber(rate = 8) {
      return Math.floor(Math.random() * 10 ** rate + 1)
    },
    preload() {},
    removeFile(file) {
      const files = this.value.filter((f) => f.id !== file.id)
      this.$emit('update-files', files)
      this.$nextTick(() => {
        document.getElementById(this.id).value = ''
        this.$emit('set-empty-error', !files.length)
        this.updateSizeError()
      })
    },
    async removeFileFromServer(file) {
      const success = await this.$store.dispatch('page/removeFile', {
        file,
        id: this.id,
      })
      if (success) this.removeFile(file)
    },
    sortFilesToPackages(files) {
      let packages = []
      let pack = []
      for (let i = 0; i < files.length; i++) {
        // сортировка файлов по пакетам до 20 мб и 20 шт.
        const blob = files[i]
        if (pack.length == 0) pack.push(blob)
        else {
          let currentPackageSize = pack.reduce(function (p, c) {
            return Number(p) + Number(c.size)
          }, '')
          if (
            Number(currentPackageSize) + Number(files[i].size) <
              20 * 1024 ** 2 &&
            pack.length < 20
          )
            pack.push(blob)
          else {
            /*  if (pack.length <= 20) */
            packages.push(pack)
            pack = []
            pack.push(blob)
          }
        }
        if (
          pack.length != 0 &&
          i == files.length - 1 /* && pack.length <= 20 */
        )
          packages.push(pack)
      }
      return packages
    },
  },
  components: { FileInput, Preview },
}
</script>
<style lang="scss" scoped>
.document {
  &__type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 56px;
    position: relative;
    background-color: $black-20;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 12px;
    overflow: hidden;

    &-name {
      margin-right: 24px;
      cursor: default;
      overflow: hidden;
      height: inherit;
    }
  }

  &__left {
    // height: 32px;
    margin: 12px 0;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
  }

  &__files {
    margin: 0 12px;
    position: relative;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > p {
    margin-bottom: 12px;
  }
}

.error {
  border: 1px solid $red-700;

  &-msg {
    margin-left: 12px;
  }
}
</style>
