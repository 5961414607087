<template>
  <div
    :class="['preview', item.invalidSize ? 'invalid-size' : '']"
    :title="item.name"
  >
    <!-- <a
      v-if="item.get"
      :href="`/proxy.php?${item.get}`"
      download
      class="preview__link"
    > -->
    <div class="preview__info">
      <p class="preview__name fz-14">{{ item.name }}</p>
      <span class="preview__size secondary fz-14">
        {{
          item.hasOwnProperty('size') ? (item.size || '0.00') + ' мБайт' : ''
        }}
      </span>
      <span v-if="item.invalidSize" class="fz-14 preview__error">
        Файл не должен превышать 20 мБайт
      </span>
    </div>
    <!-- </a> -->
    <!-- <p v-else class="preview__name">{{ item.name }}</p> -->

    <custom-button
      type="button"
      class="transparent preview__remove"
      @click.stop="$emit('remove', item)"
    >
      <Icon
        icon-name="close"
        :iconColor="!item.invalidSize ? '#CDD4DA' : '#ee1c1c'"
      />
    </custom-button>
  </div>
</template>

<script>
import CustomButton from '@/components/elements/Button'
import Icon from '@/components/elements/Icon'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: { CustomButton, Icon },
}
</script>

<style lang="scss" scoped>
.preview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  height: 40px;
  border-radius: 4px;
  background-color: $black-10;
  font-size: 14px;
  line-height: 20px;
  margin: 0 12px;
  cursor: default;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &__error {
    color: $red;
    margin-left: 16px;
  }

  // &__link {
  //   text-decoration: none;
  //   color: #333;

  //   max-width: 100%;
  //   height: 100%;
  //   display: flex;
  //   align-items: center;
  // }

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 32px;
    max-width: 525px;
  }

  &__info {
    display: flex;
    flex-wrap: nowrap;
  }

  &__remove {
    width: auto;
  }

  &.invalid-size {
    border: 1px solid $red;

    .preview__name {
      max-width: 260px;
    }
  }
}
</style>
